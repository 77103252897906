import React from 'react'
import styled from "styled-components";
// import Img from "gatsby-image/withIEPolyfill"
import H1 from '../components/typography/h1'
import P from '../components/typography/p'
import Container from '../components/container'
import H3 from '../components/typography/h3'
import InternalButton from '../components/link'


const Billboard = ({ serviceImage, children, title, link, overview, alt, price, pricingUnit, hours, hours_two, price_two, pricingUnitTwo }) => {
    return (
        <Relative>

                <Container>
                    <Block>
                        <ServiceImage>
                            {serviceImage}
                        </ServiceImage>
                        <Copy>
                            <H3>Ready for your next Adventure?</H3>
                            <Header1>{title}</Header1>
                            <P>{overview}</P>
                            {children}
                            <InternalButton link={link} buttonLabel={"Reserve Your Spot"} />
                        </Copy>
                    </Block>
                </Container>

        </Relative>
    );
};

const Relative = styled.div`
    position: relative;
    top: -300px;
    margin-bottom: -250px;
    @media (min-width: 800px) {
        margin-bottom: -17em;
    }
`

const Block = styled.div`
    @media (min-width: 800px) {
        display:flex;
        flex-direction:row;
        flex-shrink:1;
    }    
`



const Copy = styled.div`
    padding: 2.5em;
    background-color: white;
    @media (min-width: 800px) {
        width: 100%;
        margin: 0 0 0 1.5em;
    }
`

const Header1 = styled(H1)`
    margin: 0 !important;
`

const ServiceImage = styled.div`
    @media (min-width: 800px) {
        width: 100%;
    }
`



export default Billboard;