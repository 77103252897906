import React from "react"
import Layout from "../templates/layout"
import Container from '../components/container'
import H3 from '../components/typography/h3'
import P from '../components/typography/p'
import Topography from '../components/topography-section'
import ContactForm from '../components/contact-form'
import ImageGallery from 'react-image-gallery';
import { createGlobalStyle } from 'styled-components'
import styled from "styled-components";
import Hero from '../components/hero'
import Billboard from '../components/service-billboard'
import Accordion from "../components/accordion"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import "react-image-gallery/styles/css/image-gallery.css";

const IndexPage = ({ data: { prismicService } }) => {

    const { data } = prismicService

    const images = [];

    const list = data.image_gallery.map((image_data) => 
        images.push({ original: image_data.image.url, originalAlt: image_data.image.alt }),
    );

    console.log(data.faq)

    const price = new Intl.NumberFormat({ style: 'currency', currency: 'USD' }).format(data.price);
    // const priceTwo = new Intl.NumberFormat({ style: 'currency', currency: 'USD' }).format(data.price_2);
    // Got ride of second pricing option

    return (
    <Layout
        title={data.service_name.text + ' Guide on Kentucky Lake | Loveless Outdoor Adventures'}
        description={data.meta_description.text}
        image={data.open_graph_image.url}
        keyword={data.service_name.text}
    >

        {/* HERO */}
        <Hero />

        <Topography>

        {/* SERVICE BILLBOARD */}
        <Billboard serviceImage={<GatsbyImage image={data.service_image.gatsbyImageData} alt="test" />} link="#contact" title={data.service_name.text + ' on Kentucky Lake'} overview={data.service_overview.text} alt={data.service_image.alt}>
            { data.price !== null &&
                <Price>{data.hours} hour trip is {'$' + price} {data.pricing_unit}</Price>
            }
            {/* 
            GOT RID OF SECOND PRICING OPTION
            { data.price_2 !== null &&
                <Price>{data.hours_2} hour trip is {'$' + priceTwo} {data.pricing_unit}</Price>
            } */}
        </Billboard>


            {/* ABOUT SECTION */}
            <Padding>
                <Container>
                    <H3 textAlign="center">{data.about_title.text}</H3>
                    <P textAlign="center">{data.about_service.text}</P>
                </Container>
            </Padding>

            {/* { data.what_to_bring.text !== null &&
                <Padding>
                    <Container>
                        <H3 textAlign="center">What to bring</H3>
                        <P textAlign="center">{data.what_to_bring.text}</P>
                    </Container>
                </Padding>
            } */}

            {/* ACCORDION */}
            <Container>
                <Accordion faqs={data.faq}/>
            </Container>

            {/* GALLERY */}
            <Gal>
                <Container>
                    <H3 textAlign="center">Pictures from past trips</H3>
                    <ImageGallery items={images} showThumbnails={false} lazyload={true}/>
                    <GlobalStyle />
                </Container>
            </Gal>

            {/* CONTACT FORM */}
            <Padding>
                <Container>
                    <H3 textAlign="center">time to reserve your {data.service_name.text} trip</H3>
                    <ContactForm id='contact' formName={data.service_name.text} buttonName="Reserve Your Spot"/>
                </Container>
            </Padding>

        </Topography>

    </Layout>
  
)}

const Price = styled.p`
    font-family: 'Teko';
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 500;
`

const Padding = styled.div`
    padding: 3em 0;
        @media (min-width: 800px) {
            width: 900px;
            margin: 0 auto;
    }
`

const Gal = styled.div`
    padding: 3em 0;
`

const GlobalStyle = createGlobalStyle`
.image-gallery-image {
    background-color: #000;
}
`

export default IndexPage

export const pageQuery = graphql`
  query ServiceBySlug($uid: String!) {
    prismicService(uid: { eq: $uid }) {
      uid
      data {
        faq {
            faq_answer {
              text
            }
            faq_question {
              text
            }
        }
        about_service {
          text
        }
        about_title {
          text
        }
        price
        pricing_unit
        hours
        price
        service_image {
            gatsbyImageData
            alt
        }
        service_name {
          text
        }
        service_overview {
          text
        }
        what_to_bring {
            text
          }
        meta_description {
            text
        }
        open_graph_image {
            url
          }
          image_gallery {
            image {
              url
              alt
            }
          }
        }
        
    }
  }
`

