import React from 'react'
import H3 from '../components/typography/h3'
import P from '../components/typography/p'
import styled from "styled-components";
import Collapse from 'rc-collapse';
require('rc-collapse/assets/index.css');


const Accordion = ({ faqs }) => {

    var Panel = Collapse.Panel;

    if (!faqs) return null

    return (
        <div>
            <H3 textAlign="center">FAQs</H3>
            <Collapse accordion={true}>
                {faqs.map((faq) => 
                    <Panel header={faq.faq_question.text} headerClass="my-header-class"><P>{faq.faq_answer.text}</P></Panel>
                )}
            </Collapse>
        </div>
    );
};





export default Accordion;